<template>
  <section class="special_services secpadd">
    <div class="container">
      <div class="row quote1top">
        <div class="col-md-8">
          <div class="fh-section-title clearfix  text-left version-dark paddbtm40">
            <h2>{{ $store.getters['heading/getPortalHeadings'].title }}</h2>
          </div>
        </div>
      </div>
      <div class="row quote1forms">
        <div class="col-md-12 col-sm-12">
          <form>
            <div class="fh-form request-form">
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <div class="field">
                    <label>{{ getTranslation('fname') }}<span class="require">*</span></label>
                    <input class="form-control" :placeholder="getTranslation('fname')" :class="{'is-invalid':$v.form.fname.$error}" type="text" v-model.trim="form.fname">
                    <div v-if="$v.form.fname.$error" class="invalid-feedback">
                      {{ getTranslation('requiredField') }}
                    </div>
                  </div>
                  <div class="field">
                    <label>{{ getTranslation('lname') }}<span class="require">*</span></label>
                    <input class="form-control" :placeholder="getTranslation('lname')" :class="{'is-invalid':$v.form.lname.$error}" type="text" v-model.trim="form.lname">
                    <div v-if="$v.form.lname.$error" class="invalid-feedback">
                      {{ getTranslation('requiredField') }}
                    </div>
                  </div>
                  <div class="field">
                    <label>{{ getTranslation('pname') }}</label>
                    <input class="form-control" :placeholder="getTranslation('pname')" type="text" v-model.trim="form.pname">
                  </div>
                  <div class="field margbtm50">
                    <label style="display: block">{{ getTranslation('gender') }}<span class="require">*</span></label>
                    <div class="form-check col-md-6 col-sm-6">
                      <input class="form-check-input" type="radio" name="gender" v-model.number="form.gender" :value="1"
                             id="male">
                      <label class="form-check-label ml-2" for="male" :class="{'is-invalid':$v.form.gender.$error}">
                        {{ getTranslation('gender_male') }}
                      </label>
                    </div>
                    <div class="form-check col-md-6 col-sm-6">
                      <input class="form-check-input" type="radio" name="gender" v-model.number="form.gender" :value="2"
                             id="female">
                      <label class="form-check-label ml-2" for="female" :class="{'is-invalid':$v.form.gender.$error}">
                        {{ getTranslation('gender_female') }}
                      </label>
                    </div>
                    <div v-if="$v.form.gender.$error" class="invalid-feedback">
                      {{ getTranslation('chooseOneField') }}
                    </div>
                  </div>
                  <div class="field">
                    <label class="form__label--block form__label" >{{ getTranslation('bdate') }}</label>
                    <DatePicker
                        v-model="form.bdate"
                        valueType="format"
                    />
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="field">
                    <label>{{ getTranslation('username') }}<span class="require">*</span></label>
                    <input class="form-control" :placeholder="getTranslation('username')" :class="{'is-invalid':$v.form.name.$error}" type="text" v-model.trim="form.name">
                    <div v-if="$v.form.name.$error" class="invalid-feedback">
                      {{ getTranslation('requiredField') }}
                    </div>
                  </div>
                  <div class="field">
                    <label>{{ getTranslation('email') }}<span class="require">*</span></label>
                    <input class="form-control" :placeholder="getTranslation('email')" :class="{'is-invalid':$v.form.email.$error}" type="text" v-model.trim="form.email">
                    <div v-if="$v.form.email.$error" class="invalid-feedback">
                      {{ getTranslation('requiredField') }}
                    </div>
                  </div>
                  <div class="field">
                    <label>{{ getTranslation('password') }}<span class="require">*</span></label>
                    <input class="form-control" :placeholder="getTranslation('password')" :class="{'is-invalid':$v.form.password.$error}" type="password" v-model.trim="form.password">
                    <div v-if="$v.form.password.$error" class="invalid-feedback">
                      {{ getTranslation('requiredField') }}
                    </div>
                  </div>
                  <div class="field">
                    <label>{{ getTranslation('password_confirmation') }}<span class="require">*</span></label>
                    <input class="form-control" :placeholder="getTranslation('password_confirmation')" :class="{'is-invalid':$v.form.password_confirmation.$error}" type="password"
                           v-model.trim="form.password_confirmation">
                    <div v-if="$v.form.password_confirmation.$error" class="invalid-feedback">
                      {{ getTranslation('requiredField') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="field col-md-12 col-sm-12">
                  <div class="agreements">
                    <h5 class="fh-section-title">TSA Agreement</h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi commodo felis non risus tincidunt, vitae pretium lectus rutrum. Aliquam ac erat vel sem commodo fermentum non at sapien. Nullam quis
                      nibh nunc. Cras porttitor ac tortor id tristique. Integer ultricies erat id ipsum semper interdum. Aliquam erat volutpat. Phasellus tellus quam, tempor et quam sit amet, interdum egestas massa.
                      Quisque placerat consectetur nisl vel dapibus. Nullam accumsan, sapien vitae consectetur convallis, felis sapien tristique velit, vestibulum aliquam lacus odio quis est.
                    </p>
                    <p>
                      CargoJet.online Online Profile: Based on approval of your Known Shipper application, you will be provided a cargojet.online login where you can manage your shipments online 24/7 from anywhere in
                      the
                      world.
                    </p>
                    <div>
                      <div class="form-check mt-2">
                        <input class="form-check-input" type="checkbox" v-model="form.agreement"
                               id="agreement"/>
                        <label class="form-check-label ml-2" for="agreement" :class="{'is-invalid':$v.form.agreement.$error}">
                          &nbsp; {{ getTranslation('IacceptAgreement') }} <span class="require">*</span>
                        </label>
                      </div>
                      <div v-if="$v.form.agreement.$error" class="invalid-feedback">
                        {{ getTranslation('plsAcceptAgreement') }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="field col-md-6 col-sm-12">
                  <p @click.prevent="saveAgent" class="field submit">
                    <input :value="getTranslation('save')" class="fh-btn" type="submit">
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
import required from "vuelidate/lib/validators/required";
import UserFunctions from "@/classes/UserFunctions";

export default {
  name: "CreateAgent",
  data() {
    return {
      form: {
        name: '',
        email: '',
        password: '',
        fname: '',
        lname: '',
        pname: '',
        bdate: '',
        gender: '',
        password_confirmation: '',
        agreement: ''
      }
    }
  },
  created() {
    this.setPortalHeadings({
      title: this.getTranslation('registerAsAgent'),
      breadcrumbs: [
        {
          label: this.getTranslation('home'),
          route: '/'
        },
        {
          label: this.getTranslation('registration'),
          route: { name: 'portal.register' }
        },
        {
          label: this.getTranslation('registerAsAgent')
        }
      ]
    });
  },
  methods: {
    saveAgent() {

      this.$v.form.$touch();

      if ( this.$v.form.$invalid )
        return;

      return this.axios.post(this.$urls.portal.agentRegister.url, this.form)
          .then( response => {

            UserFunctions.setUserToken(response.data.data.token,true);

            UserFunctions.setAxiosAuthorization(true);

             this.$store.dispatch('user/setUser', response.data.data.viewer);

             this.$router.push({ name: 'portal.company.register' });

          }).catch(() => {
          });
    }
  },
  validations: {
    form: {
      name: { required },
      password: { required },
      fname: { required },
      lname: { required },
      email: { required },
      password_confirmation: { required },
      gender: { required },
      agreement: { required }
    }
  }

}
</script>

<style scoped>

</style>